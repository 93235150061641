<template>
  <el-container class="dashboard">
    <el-aside class="dashboard__aside" width="250">
      <el-header class="dashboard__header">
        <h1 class="app__title">
          ВК РФ
        </h1>
      </el-header>
      <el-main class="dashboard__menu">
        <side-menu />
      </el-main>
    </el-aside>
    <el-container class="dashboard__container">
      <el-header class="dashboard__header dashboard__header--right">
        <el-button class="profile" type="text" @click="goProfile">
          {{ profile.data.client_name }}
        </el-button>
        <el-button id="logout" class="logout" type="text" @click="logout">
          Выйти
        </el-button>
      </el-header>
      <router-view />
    </el-container>
  </el-container>
</template>

<script>
import SideMenu from "@/components/SideMenu/index.vue";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "Dashboard",
  components: {
    SideMenu,
  },
  computed: {
    ...mapState("profile", ["profile"]),
  },
  methods: {
    ...mapActions("auth", { refreshToken: "REFRESH_TOKEN" }),
    ...mapMutations("auth", { setToken: "SET_TOKEN" }),
    ...mapMutations("profile", { setProfile: "SET_PROFILE" }),
    goProfile() {
      this.$router.push({ name: "profile" });
    },
    logout() {
      this.setToken(null);
      this.setProfile(null);
      localStorage.removeItem("token");
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style>
.dashboard {
  display: flex;
}

.dashboard__aside {
  height: 100vh;
  min-width: 250px;
  padding: 0;
  margin: 0;
  border-right: 1px solid #edf2fc;
}

.dashboard__menu {
  padding: 0;
}

.dashboard__container {
  width: calc(100% - 200px);
}

.dashboard__header {
  background-color: #edf2fc;
  line-height: 60px;
}
.dashboard__header--right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dashboard .profile {
  color: #409eff;
  margin-right: 15px;
  font-size: 14px;
}
.dashboard .logout {
  margin-right: 15px;
  color: #409eff;
  line-height: 36px;
  font-size: 14px;
}
.page__container {
  width: 100%;
  height: calc(100vh - 120px);
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.el-main.page__container > .container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: -20px;
  padding: 20px;
}
.page__form {
  margin: 10px 0 0 0;
}
.page__active_bar {
  background-color: #edf2fc;
}
.page__active_button {
  float: right;
  margin-top: 11px;
  margin-left: 11px;
}
.delimiter {
  border: 1px solid #edf2fc;
}
.el-table .cell {
  word-break: break-word;
}
.el-header {
  padding: 0;
}
.link {
  line-height: 1;
  cursor: pointer;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  color: #409eff;
  text-decoration: underline;
}

.el-form {
  max-width: 1000px;
}

.app__title {
  margin: 0;
  padding-left: 20px;
}
</style>
