<template>
  <el-menu :router="true">
    <el-menu-item
      v-for="item in sideMenuList"
      :id="item.id"
      :key="item.key"
      :default-active="activeIndex"
      :route="item.route"
      :index="item.index"
    >
      <fa class="menu-item-icon" :icon="item.icon" />{{ item.name }}
    </el-menu-item>
  </el-menu>
</template>

<script>
import { FontAwesomeIcon as fa } from "@fortawesome/vue-fontawesome";
import { faUserTie, faVideo, faServer, faCog, faTh, faBarcode, faRobot } from "@fortawesome/free-solid-svg-icons";
const sideMenuList = [
  {
    id: "listOperators",
    name: "Операторы",
    route: { name: "operators", params: { page: 1 } },
    index: "operators",
    icon: faVideo,
  },
  {
    id: "listDvrServers",
    name: "DVR-сервера",
    route: { name: "dvr-servers", params: { page: 1 } },
    index: "dvr-servers",
    icon: faServer,
  },
  {
    id: "listServers",
    name: "Proxy-сервера",
    route: { name: "servers", params: { page: 1 } },
    index: "servers",
    icon: faServer,
  },
  {
    id: "listModels",
    name: "Модели камер",
    route: { name: "models", params: { page: 1 }},
    index: "models",
    icon: faTh,
  },
  {
    id: "listUsers",
    name: "Сотрудники",
    route: { name: "users", params: { page: 1 } },
    index: "users",
    icon: faUserTie,
  },
  {
    id: "listM2m",
    name: "M2M-клиенты",
    route: { name: "m2m", params: {} },
    index: "m2m",
    icon: faCog,
  },
  {
    id: "serialNumbers",
    name: "Серийные номера",
    route: { name: "serial-numbers:create", params: {} },
    index: "serial-numbers",
    icon: faBarcode,
  },
  {
    id: "reserveTransfers",
    name: "Автоматическое резервирование",
    route: { name: "reserve-transfers", params: {} },
    index: "reserve-transfers",
    icon: faRobot,
  },
];
export default {
  name: "SideMenu",
  components: { fa },
  data() {
    return {
      sideMenuList,
      activeIndex: "operators",
    };
  },
};
</script>

<style scoped>
.el-menu {
  height: calc(100% - 60px);
  list-style: none;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
}
.menu-item-icon {
  width: 20px;
  text-align: center;
  margin-right: 15px;
}
</style>
